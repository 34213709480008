/* --------------------------------------------------------------------------------------
   baseProvider.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { createContext } from 'preact'
import { IntlProvider } from 'preact-i18n'
import { useState, useEffect } from 'preact/hooks'
import enIntlDef from '../assets/i18n/en.json'
import { getAppGallerySettings } from '../modules/appGallery'
import { getDeviceInfoValues } from '../modules/deviceModel'
import { sessionVar } from '../constants/storage.const'
import { setSessionVar } from '../modules/browserStorage'
import ErrorProvider from './errorProvider'

export const baseContext = createContext()

const BaseProvider = ({ children }) => {
  const [activity, setActivity] = useState()
  const [bannerContent, setBannerContent] = useState()
  const [deviceInformation, setDeviceInformation] = useState()
  const [buttonClick, setButtonClick] = useState(0)
  const [appGallerySettings] = useState(getAppGallerySettings())
  const [showFileTypeModal, setShowFiletypeModal] = useState(false)
  const { community, key, serverUrl, license, data } = appGallerySettings || {}
  const buttonWasClicked = () => setButtonClick(buttonClick + 1)
  //TODO: Refactor this language detection code and move it somewhere else
  let intlDefinition = enIntlDef
  let supportedLanguages = require('../assets/i18n/supported-languages.json')
  const deviceLanguage = window.navigator.language.split('-')[0]
  const sessionVariables = [
    { key: sessionVar.communityString, value: community },
    { key: sessionVar.apiKey, value: key },
    { key: sessionVar.hylandServerUrl, value: serverUrl },
    { key: sessionVar.hylandLicense, value: license },
    { key: sessionVar.hylandDatasource, value: data }
  ]
  if (supportedLanguages.includes(deviceLanguage)) {
    intlDefinition = require(`../assets/i18n/${deviceLanguage}.json`)
  }
  useEffect(() => {
    sessionVariables.map(s => setSessionVar(s.key, s.value))
    getDeviceInfoValues().then(r => setDeviceInformation(r))
  }, [])
  useEffect(
    () =>
      deviceInformation &&
      setSessionVar(sessionVar.macAddress, deviceInformation.mac),
    [deviceInformation]
  )

  const contextElements = {
    activity,
    setActivity,
    bannerContent,
    setBannerContent,
    deviceInformation,
    setDeviceInformation,
    buttonClick,
    buttonWasClicked,
    deviceLanguage,
    appGallerySettings,
    showFileTypeModal,
    setShowFiletypeModal
  }
  return (
    <baseContext.Provider value={contextElements}>
      <IntlProvider definition={intlDefinition}>
        <ErrorProvider>{children}</ErrorProvider>
      </IntlProvider>
    </baseContext.Provider>
  )
}

export default BaseProvider
