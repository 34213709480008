/* --------------------------------------------------------------------------------------
   scanSettings.const.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

export const scanSettingsOptions = {
    TwoSided: [
        // TODO: Split in scan and print, no print in onedrive app
        { key: 'OneSided', value: 'SCAN_SETTINGS.ONE_SIDED', scope: ['scan', 'print'], img: "duplex_sided_one_48.svg" },
        { key: 'TwoSided', value: 'SCAN_SETTINGS.TWO_SIDED_LONG', scope: ['scan'], img: "duplex_sided_two_48.svg" },
        { key: 'SecondSideRotation', value: 'SCAN_SETTINGS.TWO_SIDED_SHORT', scope: ['scan'], img: "duplex_sided_rotate_48.svg" }
    ],
    Resolution: [
        { key: '150', value: '150 dpi', img: "resolution_150_48.svg", altalink: true },
        { key: '200', value: '200 dpi', img: "resolution_200_48.svg" },
        { key: '300', value: '300 dpi', img: "resolution_300_48.svg" },
        { key: '400', value: '400 dpi', img: "resolution_400_48.svg" },
        { key: '600', value: '600 dpi', img: "resolution_600_48.svg" }
    ],
    OutputColor: [
        { key: 'Auto', value: 'SCAN_SETTINGS.AUTO_DETECT', img: "output_color_auto_48.svg" },
        { key: 'FullColor', value: 'SCAN_SETTINGS.COLOR', img: "output_color_full_48.svg" },
        { key: 'BlackAndWhite', value: 'SCAN_SETTINGS.BLACK_AND_WHITE', img: "output_color_black_white_48.svg" },
        { key: 'Grayscale', value: 'SCAN_SETTINGS.GRAYSCALE', img: "output_color_grayscale_48.svg" }
    ],
    OriginalOrientation: [
        { key: 'Portrait', value: 'SCAN_SETTINGS.PORTRAIT', img: "orientation_portrait_48.svg" },
        { key: 'Landscape', value: 'SCAN_SETTINGS.LANDSCAPE', img: "orientation_landscape_48.svg" },
    ],
    OriginalSize: [
        { key: 'Auto__SIZE', value: 'SCAN_SETTINGS.AUTO_DETECT', glyph: '' },
        { key: 'NA_8.5x11LEF', value: 'SCAN_SETTINGS.8_5X11', glyph: 'xglyph-long-edge-feed' },
        { key: 'NA_8.5x11SEF', value: 'SCAN_SETTINGS.8_5X11', glyph: 'xglyph-short-edge-feed' },
        { key: 'NA_8.5x14SEF', value: 'SCAN_SETTINGS.8_5X14', glyph: 'xglyph-short-edge-feed' },
        { key: 'NA_11x17SEF', value: 'SCAN_SETTINGS.11X17', glyph: 'xglyph-short-edge-feed', a3: true },
        { key: 'NA_5.5x8.5LEF', value: 'SCAN_SETTINGS.5_5X8_5', glyph: 'xglyph-long-edge-feed', },
        { key: 'NA_5.5x8.5SEF', value: 'SCAN_SETTINGS.5_5X8_5', glyph: 'xglyph-short-edge-feed' },
        { key: 'NA_8.5x13SEF', value: 'SCAN_SETTINGS.8_5X13', glyph: 'xglyph-short-edge-feed' },
        { key: 'ISO_A4LEF', value: 'A4', glyph: 'xglyph-long-edge-feed' },
        { key: 'ISO_A4SEF', value: 'A4', glyph: 'xglyph-short-edge-feed' },
        { key: 'ISO_A3SEF', value: 'A3', glyph: 'xglyph-short-edge-feed', a3: true },
        { key: 'ISO_A5LEF', value: 'A5', glyph: 'xglyph-long-edge-feed' },
        { key: 'ISO_A5SEF', value: 'A5', glyph: 'xglyph-short-edge-feed' },
        { key: 'ISO_B4SEF', value: 'B4', glyph: 'xglyph-short-edge-feed' },
        { key: 'ISO_B5LEF', value: 'B5', glyph: 'xglyph-long-edge-feed' },
        { key: 'ISO_B5SEF', value: 'B5', glyph: 'xglyph-short-edge-feed' },
        { key: 'NA_5.5x7SEF', value: 'SCAN_SETTINGS.5_5X7', glyph: 'xglyph-short-edge-feed' },
        { key: 'NA_5.5x7LEF', value: 'SCAN_SETTINGS.5_5X7', glyph: 'xglyph-long-edge-feed' }
    ],
    OriginalType: [
        { key: 'PhotoAndText', value: 'SCAN_SETTINGS.TEXT_AND_PHOTO', img: "original_type_text_photo_48.svg" },
        { key: 'Photo', value: 'SCAN_SETTINGS.PHOTO', img: "original_type_photo_48.svg" },
        { key: 'Text', value: 'SCAN_SETTINGS.TEXT', img: "original_type_text_48.svg" },
        { key: 'Map', value: 'SCAN_SETTINGS.MAP', img: "original_type_map_48.svg", altalink: true },
        { key: 'NewspaperOrMagazine', value: 'SCAN_SETTINGS.NEWSPAPER_MAGAZINE', img: "original_type_newspaper_48.svg" }
    ],
    Stapling: [
        { key: 'Staple', value: 'ON', img: "orientation_portrait_48.svg" },
        { key: 'None', value: 'OFF', img: "orientation_portrait_48.svg" },
    ],
    Quality: [
        { key: 'HighCompression', value: 'SCAN_SETTINGS.GOOD_QUALITY', shortValue: "SCAN_SETTINGS.GOOD_QUALITY_SHORT", img: "quality_file_size_good.svg" },
        { key: 'MediumCompression', value: 'SCAN_SETTINGS.BETTER_QUALITY', shortValue: "SCAN_SETTINGS.BETTER_QUALITY_SHORT", img: "quality_file_size_better.svg" },
        { key: 'LowCompression', value: 'SCAN_SETTINGS.BEST_QUALITY', shortValue: "SCAN_SETTINGS.BEST_QUALITY_SHORT", img: "quality_file_size_best.svg" },

    ]
}