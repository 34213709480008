/* --------------------------------------------------------------------------------------
   jobSettingsProvider.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { createContext } from 'preact'
import { useState } from 'preact/hooks'
import { clearUserScanSettings, getInitialScanSettings, storeScanSettings } from "../modules/scanSettingsOptions";
import { getSelectedJobSettings } from '../modules/jobSettings'
import { defaultFileName, parseFilename } from '../modules/filename'
import { MFDApp } from '../constants/MFDApp.const';
import { defaultFiletypeSettings } from '../modules/filetype';

export const jobSettingsContext = createContext()

const JobSettingsProvider = props => {
  const [scanFilename, setScanFilename] = useState(defaultFileName)
  const initialScanSettings = getInitialScanSettings(MFDApp.scanSettings.items)
  const [scanSettings, setScanSettings] = useState(initialScanSettings)
  const [scanGuid, setScanGuid] = useState()
  const [ filetypeSettings, setFiletypeSettings ] = useState(defaultFiletypeSettings)
  const selectedScanSettings = noApiNames => getSelectedJobSettings(scanSettings, noApiNames)
  const storeUserScanSettings = () => storeScanSettings(selectedScanSettings(true))
  const getParsedScanFilename = serial => parseFilename(scanFilename, serial)
  const resetFilename = () => setScanFilename(defaultFileName)
  const resetJobDocumentSettings = () => {
    resetFilename()
  }
  const resetJobSettings = () => {
    clearUserScanSettings()
    setScanSettings(getInitialScanSettings(MFDApp.scanSettings.items))
  }

  const resetFiletypeSettings = () =>{
    setFiletypeSettings(defaultFiletypeSettings);
  }

  const colorIsBwOrAuto = ["Auto", "BlackAndWhite"].includes(selectedScanSettings().colorMode)

  const contextElements = {
    scanFilename,
    setScanFilename,
    scanSettings,
    setScanSettings,
    selectedScanSettings,
    storeUserScanSettings,
    getParsedScanFilename,
    resetFilename,
    resetJobDocumentSettings,
    resetJobSettings,
    scanGuid,
    setScanGuid,
    filetypeSettings,
    resetFiletypeSettings,
    setFiletypeSettings,
    colorIsBwOrAuto
  }

  return (
    <jobSettingsContext.Provider value={contextElements}>
      {props.children}
    </jobSettingsContext.Provider>
  )
}

export default JobSettingsProvider
