/* --------------------------------------------------------------------------------------
   errorTypes.const.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

// NOTE: FM_001 is built and returned by serviceStatusCheck.js

export const errorTypes = {
  ecommerceError: {
    code: "FM_002",
    name: "Xerox eCommerce issue",
    title: "ERROR.APP_UNAVAILABLE_AT_THIS_TIME",
    textKeys: [
      "ERROR.TRY_AGAIN",
      "ERROR.CONTACT_XEROX_SUPPORT"
    ]
  },
  appGalleryError: {
    code: "FM_003",
    name : "App Gallery Configuration issue",
    title: "ERROR.APP_CONFIGURATION_ERROR",
    textKeys: [
      "ERROR.CHECK_APP_CONFIGURATION_SETTINGS"
    ]
  },
  networkError: {
    code: "FM_004",
    status: 0,
    name : "App cannot connect to the network.",
    title: "ERROR.THE_APP_CANNOT_CONNECT_TO_NETWORK",
    textKeys: [
      "ERROR.CHECK_YOUR_DEVICE_NETWORK_CONNECTION"
    ]
  },
  genericError: {
    code: "FM_005",
    name: "Generic Error",
    title: "ERROR.AN_ERROR_HAS_OCCURRED",
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },
  scanError: {
    code: "FM_006",
    name: "A scan error has occurred.",
    title: "ERROR.SCAN_ERROR",
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },
  scanUploadError: {
    code: "FM_007",
    name: "Failed to upload your scanned file to the scan queue",
    title: "ERROR.SCAN_UPLOAD_ERROR",
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },

  scanQueuesAccessError: {
    code: "FM_008",
    name: "Unable to access scan queues",
    title: "ERROR.UNABLE_TO_ACCESS_SCAN_QUEUE",
    textKeys: ["ERROR.RESTART_APP_AND_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"]
  },
  sessionTimeoutError: {
    code: "FM_009",
    status: 401,
    name: "Session Timed Out",
    title:"ERROR.SESSION_TIMEOUT",
    textKeys: ["ERROR.LOGIN_AGAIN"]
  },


  maintenanceModeError: {
    code: "FM_011",
    name: "Installing App Updates",
    title: "ERROR.INSTALLING_APP_UPDATES",
    textKeys: ["ERROR.THIS_MAY_TAKE_SEVERAL_MINUTES", "ERROR.CLOSE_ALERT_CONTINUE_WORKING"]
  },
  badRequestError: {
    code: "FM_012",
    name: "Generic Error",
    title: "ERROR.AN_ERROR_HAS_OCCURRED",
    status:400,
    textKeys: ["ERROR.PLEASE_TRY_AGAIN", "ERROR.IF_PROBLEM_CONTACT_XEROX"],
  },
  loginError: {
    code: "FM_013",
    name: "Login Error",
    title: "ERROR.LOGIN_ERROR",
    textKeys:[]
  },
  

};