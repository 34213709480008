/* --------------------------------------------------------------------------------------
   filename.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import * as dayjs from 'dayjs'

const isFrench = navigator.language.split("-")[0] === "fr"
export const defaultFileName = `Xerox Scan`


export const parseFilename = (filename, serial) => parseFunctions.reduce((acc, fn) => fn(acc, serial), filename)

//replaceAll: local shim
const replaceAll = (source, str, replacement) => source.split(str).join(replacement);
const parseDayJsInfocode = (filename, code, format) => replaceAll(filename, code, dayjs().format(format))

const dateTimeFormat = isFrench? "YYYY-MM-DD HH.mm.ss" : "YYYY-MM-DD hh.mm.ss A"

const parseInfocodeDate = filename => parseDayJsInfocode(filename, "%D", "YYYYMMDD")
const parseInfocodeTime = filename => parseDayJsInfocode(filename, "%T", "HHmmss")
const parseInfocodeYear = filename => parseDayJsInfocode(filename, "%Y", "YYYY")
const parseInfocodeHour = filename => parseDayJsInfocode(filename, "%H", "HH")
const parseInfocodeMinute = filename => parseDayJsInfocode(filename, "%M", "mm")
const parseInfocodeSecond = filename => parseDayJsInfocode(filename, "%S", "ss")
const parseInfocodeDay = filename => parseDayJsInfocode(filename, "%d", "DD")
const parseInfocodeSerial = (filename, serial) => replaceAll(filename, "%sn", serial)
const cleanUnsupportedCodes = filename => replaceAll(filename, "%", "")

const parseFunctions = [
    parseInfocodeDate,
    parseInfocodeTime,
    parseInfocodeYear,
    parseInfocodeHour,
    parseInfocodeMinute,
    parseInfocodeSecond,
    parseInfocodeDay,
    parseInfocodeSerial,
    cleanUnsupportedCodes
]
