/* --------------------------------------------------------------------------------------
   index.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/


const BaseModal = props => {
    const modalTypeClass = props.alert? "alert" : "popup-blanket"
    const modalDisplayStyle = props.display ? "block" : "none"
    return <div class={`base-modal-background ${modalTypeClass}`} style={{display: modalDisplayStyle}}>
        {props.alert ?
        
        <div class="base-modal-alert content-wrapper">
        <div 
            class={`base-modal-alert content ${props.noActionBar || props.alert ? "" : 'action-bar-padding'}`}
        >
            {props.children}
        </div>
        </div>
        :
        <div 
            class={`popup-content ${props.noActionBar || props.alert? null : 'action-bar-padding'}`}
        >
            {props.children}
        </div>
    }
    </div>
}
export default BaseModal
