/* eslint-disable no-extra-boolean-cast */
/* --------------------------------------------------------------------------------------
   util.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/
export const deviceUrl = location.port == "8080" ? process.env.PREACT_APP_DEBUG_DEVICE_IP : null

export const generateUuid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
      return `%${  (`00${  c.charCodeAt(0).toString(16)}`).slice(-2)}`;
  }).join(''));

  return JSON.parse(jsonPayload);
}
export const getSearchParams = paramsObj => {
  const result = new URLSearchParams()
  Object.keys(paramsObj).forEach(k => {
    result.append(k, paramsObj[k])
  });
  return result
}

export const getUrlSearchParamsAsObj = () => {
  const queryStringParameters = new URLSearchParams(window.location.search);
  const entries = queryStringParameters.entries();
  let obj = {};
  for (let i = entries.next(); !i.done; i = entries.next()) {
    const [key, value] = i.value;
    obj[key] = sanitizeQueryStringValue(value)
  }
  return obj;
}

const sanitizeQueryStringValue = value => decodeURIComponent(value).replace(/[^a-zA-Z0-9\-._~:/?#[\]@!$&()*+,;=]/g, '')

export const deepCopy = (obj) =>{
  return JSON.parse(JSON.stringify(obj))
}

export const exitApp = () => {
  try {
    ExitCUIMode()
  } catch (error) {
    console.log("ExitCUIMode() function not found")
    location.href = "about:blank"
  }
}

export const is5inches = screen.width < 801
export const vwToPx = (vWidth) =>{
  return screen.width * vWidth / 100;
}
export const closeBracketFunc = () => {
  const [closeBracket] = document.getElementsByClassName('ui-keyboard-58033')
  if (closeBracket) {
    closeBracket.attributes.getNamedItem('data-value').textContent = '}'
    closeBracket.attributes.getNamedItem('data-action').textContent = '}'
    closeBracket.attributes.getNamedItem('data-name').textContent = '}'
    closeBracket.attributes.getNamedItem(
      'data-html'
    ).textContent = `<span class="ui-keyboard-text">}</span>`
    if (closeBracket?.firstChild) {
      closeBracket.firstChild.textContent = '}'
    }
  }
}

