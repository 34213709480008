/* --------------------------------------------------------------------------------------
   xwActionBar.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { useRef, useEffect } from "preact/hooks"
import { MFDApp } from "../../constants/MFDApp.const"

//TODO: safe destroy is calling a wrongly referenced variable, fix and check that it doesn't break anything
// Check because this issue is present in other widgets as well
const XwActionBar = props => {
    const elementRef = useRef(null)
    useEffect(() => {
        $(elementRef.current).xrxactionbar(getOptions(props))
        return () => safeDestroy()
    }, [])
    return <div ref={elementRef} id={props.id}>
        {props.children}
    </div>
}

const getOptions = props => ({
    ...props,
    theme: props.theme || MFDApp.theme,
    children: undefined
})

const safeDestroy = () => {
    try {
        $(elementRef.current).xrxactionbar("destroy")
    } catch(e) {}
}

export default XwActionBar