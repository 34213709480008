/* --------------------------------------------------------------------------------------
   browserStorage.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/
import { MFDApp } from "../constants/MFDApp.const";

const prefixKey = key => `${MFDApp.storagePrefix}${key}`

export const setSessionVar = (key, value) => value && sessionStorage.setItem(prefixKey(key), value)
export const getSessionVar = (key) => sessionStorage.getItem(prefixKey(key))
export const setLocalVar = (key, value) => value && localStorage.setItem(prefixKey(key), value)
export const getLocalVar = (key) => localStorage.getItem(prefixKey(key))
export const clearLocalVar = key => localStorage.removeItem(prefixKey(key))