/* --------------------------------------------------------------------------------------
   app.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
	 
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { h } from 'preact';

import { useEffect } from 'preact/hooks';
import { deviceUrl } from '../modules/util';
import BaseProvider from '../providers/baseProvider';
import Router from 'preact-router';
import Home from '../routes/home';
import Queues from '../routes/queues';
import Scan from '../routes/scan';
import AppProvider from '../providers/appProvider';
import { setSessionVar } from '../modules/browserStorage';
import { sessionVar } from '../constants/storage.const';
import { onRouteChange } from '../modules/routing';
import Login from '../routes/login';
import Xeroxsso from '../routes/xeroxsso';
import { MFDApp } from '../constants/MFDApp.const';


const App = () => {
	useEffect(() => setSessionVar(sessionVar.deviceUrl, deviceUrl), [])
	//NOTE: There's a browser race condition where if we use the wrapper function, the value is not removed in time, 
	// so we use the vanilla js function instead
	const appStartedSessionStorageKey = `${MFDApp.storagePrefix  }appStarted`
	useEffect(() => window.onbeforeunload = () => sessionStorage.removeItem(appStartedSessionStorageKey), [])
	
	//NOTE: Router onChange applies to all blocks even if it's declared on the first one only
	return <div id="app">
		<BaseProvider>
			<Router onChange={onRouteChange}>
				<Home path="/" />
				<Xeroxsso path="/xeroxsso" />
				<Login path="/login" />
			</Router>
			<AppProvider>
				<Router>
					<Queues path="/queues" />
					<Scan path="/scan" />
				</Router>
			</AppProvider>
		</BaseProvider>
	</div>
}

export default App;
