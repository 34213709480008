/* --------------------------------------------------------------------------------------
   errorProvider.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { createContext } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { errorTypes } from '../constants/errorTypes.const'
import ErrorAlert from '../components/errorAlert'
import { exitApp } from '../modules/util'
export const errorContext = createContext()
const ErrorProvider = ({ children }) => {
  const [errorAlert, setErrorAlert] = useState()
  const setError = (error) =>{
    setErrorAlert({error})
  };
  const error = errorAlert?.error;
  useEffect(() => {
    function handleNetworkError () {
      setError(errorTypes.networkError)
    }
    window.addEventListener('network-error', handleNetworkError)
    return () => {
      window.removeEventListener('network-error', handleNetworkError)
    }
  }, [])
  const contextElements = {
    errorAlert,
    error,
    setErrorAlert,
    setError
  }

  return (
    <errorContext.Provider value={contextElements}>
      {children}
      {!!errorAlert && (
        <ErrorAlert
          error={errorAlert?.error}
          onClose={() => exitApp()}
          { ...errorAlert?.secondaryAction ? {secondaryAction: errorAlert.secondaryAction} : {} }
          {...errorAlert.closeButtonText ? {closeButtonText:errorAlert.closeButtonText} : {}}
          {...errorAlert.closeIcon ? {closeIcon:errorAlert.closeIcon} : {}}
        />
      )}
    </errorContext.Provider>
  )
}

export default ErrorProvider
