/* --------------------------------------------------------------------------------------
   deviceModel.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { getDeviceInfo } from "../xerox/eipClient/deviceConfig"
import { xrxGetElementValue, xrxStringToDom } from "../xerox/modular-xerox-js-library/xrxjslib.module"

const modelFieldPath = "DeviceInformation > device > model"
const serialFieldPath = "DeviceInformation > device > serial"
const macFieldPath = "DeviceInformation > device > mac"

export const getDeviceInfoValues = () => getDeviceInfo().then(r => ({
    serial: getDeviceInfoPathField(r, serialFieldPath),
    model: getDeviceInfoPathField(r, modelFieldPath),
    mac: getDeviceInfoPathField(r, macFieldPath),

}))


const getDeviceInfoPathField = (deviceInfoString, fieldPath) => $(getDeviceInfoField(getDeviceInformationFieldString(deviceInfoString))).find(fieldPath).text()

const getDeviceInformationFieldString = deviceInfoString => xrxGetElementValue(xrxStringToDom(deviceInfoString), "Information")
const getDeviceInfoField = deviceInformationFieldString => xrxStringToDom(deviceInformationFieldString)
