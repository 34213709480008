/* --------------------------------------------------------------------------------------
   xwBanner.js
   Copyright © 2023 Xerox Corporation. All Rights Reserved.
     
   Copyright protection claimed includes all forms and matters of copyrightable material
   and information now allowed by statutory or judicial law or hereinafter granted,
   including without limitation, material generated from the software programs which
   are displayed on the screen such as icons, screen display looks, etc.
--------------------------------------------------------------------------------------*/

import { useRef, useEffect, useState } from "preact/hooks"
import { MFDApp } from "../../constants/MFDApp.const"

const XwBanner = props => {
    const elementRef = useRef(null)
    const elementId = `bnr-${new Date().getTime()}`
    const [initialized, setInitialized] = useState(false)
    const timeout = props.timeout ? props.timeout : 3000;
    useEffect(() => {
        setTimeout(() => {
            if (!initialized) {
                $(elementRef.current).xrxbanner(getOptions(props))      
                setInitialized(true)     
            }
        }, 200);
        //return () => $(elementRef.current).xrxbanner("destroy")
    },[])
    useEffect(() => {
        if (initialized) {
            const action = props.display? 'open' : 'close'
            $(elementRef.current).xrxbanner(action)
            props.display ? props.onBannerShow && props.onBannerShow() : props.onBannerHide && props.onBannerHide();

            if(props.display){
                //by doing this, we own the timeout and add a callback to it.
                setTimeout(()=>{
                    $(elementRef.current).xrxbanner('close');
                    props.onBannerHide && props.onBannerHide();
                }, timeout);
            }
        }
    }, [props.display]) 
    return <div ref={elementRef} id={elementId}>
        {props.children}
    </div>
}

const getOptions = props => ({
    ...props,
    theme: props.theme || MFDApp.theme,
    children: undefined
})

export default XwBanner